import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Component } from "react";

/* COMPONENTS */
import Cards from "../../ui/Cards";
import Contacts from "../../ui/Contacts";
import Title from "../../ui/Title";
import IntroImage from "../../ui/IntroImage";
import PageContainer from "../../ui/PageContainer";
import Footer from "../../ui/Footer";

import consulting from "./img/consulting.svg";
import partneri from "./img/partneri.svg";
import reality from "./img/reality.svg";
import property from "./img/property.svg";
import sreality from "./img/sreality.png";

import styles from "./style.module.scss";
import { withFirebase } from "../../components/Firebase";

class Uvod extends Component {
    state = {
        loading: true,
        companies: {},
        contacts: [],
        cards: [],
    };

    componentDidMount() {
        this.setState({ loading: true });

        // this.props.firebase.getImage();

        this.props.firebase.database().on("value", (snapshot) => {
            const database = snapshot.val();

            const cards = database.cards && Object.values(database.cards);

            this.setState({
                companies: database.companies,
                contacts: database.contacts,
                cards: database.cards === undefined ? [] : cards,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.database().off();
    }

    render() {
        const { cards: notSorted, companies, contacts } = this.state;

        let prodej = [];
        let pripravujeme = [];
        let realizovano = [];

        notSorted.forEach((card) => {
            switch (card.flag) {
                case "prodej":
                    prodej.push(card);
                    break;
                case "pripravujeme":
                    pripravujeme.push(card);
                    break;
                default:
                    realizovano.push(card);
                    break;
            }
        });

        prodej.sort((a, b) => {
            return b.date - a.date;
        });
        pripravujeme.sort((a, b) => {
            return b.date - a.date;
        });
        realizovano.sort((a, b) => {
            return b.date - a.date;
        });

        const cards = [...prodej, ...pripravujeme, ...realizovano];

        return (
            <React.Fragment>
                <IntroImage id="domu" />
                <PageContainer>
                    <Title id="nase_sluzby" text="Naše služby" />
                    <Cards
                        cardClassName={styles.serviceCard}
                        servicesVariant
                        list={[
                            {
                                icon: "key",
                                title: "Developerské projekty",
                                subtitles: [
                                    "Vyhledáváme pro investory investiční příležitosti.",
                                    "Zajistíme přípravu projektové dokumentace a samotný inženýring.",
                                    "Připravíme veškerou smluvní dokumentaci.",
                                    "Realizujeme samotný prodej.",
                                    "Prohlášení vlastníka a předání nemovitosti je samozřejmostí.",
                                ],
                            },
                            {
                                icon: "university",
                                title: "Reality",
                                subtitles: [
                                    "Realizujeme prodeje vašich nemovitostí.",
                                    "Vaše investiční nemovitosti pronajmeme.",
                                    "Prodej děláme i formou aukce přes portál ",
                                    "Pomůžeme vám s vyhledáním nemovitosti dle vašich představ.",
                                    "Zajistíme veškerou smluvní dokumentaci.",
                                ],
                            },
                            {
                                icon: "tie",
                                title: "Poradenství v oblasti financí",
                                iconMargin: true,
                                subtitles: [
                                    "Srovnáme nabídky jednotlivých bank.",
                                    "Poradíme se zajištěním rizik spojených s nemocí či úrazem.",
                                    "Postaráme se, aby i váš majetek byl správně ochráněn.",
                                    "Sestavíme investiční portfolio na míru.",
                                    "Zajistíme veškerou administrativu a následný servis.",
                                ],
                            },

                            {
                                icon: "social-1",
                                title: "Správa investičních bytů",
                                subtitles: [
                                    "Vaše investiční nemovitosti obsadíme nájemci.",
                                    "O všechny starosti v průběhu nájmu se staráme my.",
                                    "Zajistíme všechna pojištění a právní agendu.",
                                    "Pravidelně vám zasíláme reporty.",
                                    "Každoročně připravíme daňové přiznání.",
                                ],
                            },
                        ]}
                    />

                    <Title
                        id="developerske_projekty"
                        text="Developerské projekty"
                    />
                    <Cards list={cards} />

                    <div className={styles.reality}>
                        <Title noMargin id="reality" text="Reality" />
                        Aktuální realitní nabídka na{" "}
                        <a
                            href="https://www.sreality.cz/adresar/vlpartneri-s-r-o-brno-brno-mesto/13601/inzeraty"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.link}
                        >
                            <img
                                className={styles.sreality}
                                src={sreality}
                                alt="sreality"
                            />
                        </a>
                    </div>

                    <Title id="kontakt" text="Kontakty" />
                    <Contacts list={contacts && contacts} />

                    <Cards
                        cardClassName={styles.companies}
                        servicesVariant
                        list={[
                            {
                                companyLogo: partneri,
                                ...(companies && companies.partneri),
                            },
                            {
                                companyLogo: reality,
                                ...(companies && companies.reality),
                            },
                            {
                                companyLogo: consulting,
                                ...(companies && companies.consulting),
                            },

                            {
                                companyLogo: property,
                                ...(companies && companies.financial),
                            },
                        ]}
                    />
                    <p />
                </PageContainer>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withFirebase(Uvod);
