import React, { Component } from 'react';

import styles from './style.module.scss';
import m3logo from './assets/m3logo.svg';

class AdminHeader extends Component {
    render() {
        const { children } = this.props;

        return (
            <div className={styles.page}>
                <img src={m3logo} alt="logo" className={styles.logo}/>
                <div className={styles.form}>
                    {children}
                </div>
            </div>
        );
    }; 
};

export default AdminHeader;