import React, { Component } from "react";
// import classNames from "classnames";

import styles from "./style.module.scss";

import Icon from "../Icon";

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
        };
    }

    setWidth = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const {
            data: { name, desc, email, phone },
        } = this.props;

        return (
            <div className={styles.contact}>
                <div className={styles.contact_iconbox}>
                    <Icon name="user" className={styles.contact_iconbox_icon} />
                </div>

                {name && <div className={styles.contact_title}>{name}</div>}
                {desc && <div className={styles.contact_line_grey}>{desc}</div>}
                {phone && <div className={styles.contact_line}><a className={styles.contact_link} href={"tel:" + phone}>{phone}</a></div>}
                {email && (
                    <div className={styles.contact_line_bold}>
						<a className={styles.contact_link} href={"mailto:" + email}>{email}</a>
					</div>
                )}
            </div>
        );
    }
}

export default Contact;
