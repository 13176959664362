import React, { Component } from 'react';
import { TweenLite } from 'gsap';

import styles from './style.module.scss';

class Link extends Component {
    constructor(props) {
        super(props);

        this.underlineRef = React.createRef();
    }

    handleShowUnderline = () => {
		TweenLite.to(this.underlineRef.current, 0.2, {
            opacity: 1,
            width: '35px',
		});
	};

    handleHideUnderline = () => {
		TweenLite.to(this.underlineRef.current, 0.2, {
            opacity: 0,
            width: '0%',
		});
    };
    

    render() {
        const { name } = this.props;

        return (
            <div onClick={this.props.onClick} className={styles.link} onMouseEnter={this.handleShowUnderline} onMouseLeave={this.handleHideUnderline}>
                {name}
                <div className={styles.underline} ref={this.underlineRef}></div>
            </div>
        );
    }; 
};

export default Link;