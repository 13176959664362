import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

import Card from '../Card';

class Cards extends Component {
	render() {
		const { list, servicesVariant, className, cardClassName } = this.props;

		return (
			<div className={classNames(styles.cards, className)}>
				{list.map(( item, i) => {
					return (
						<Card
							className={cardClassName}
							idx={i}
							key={i}
							item={item}
							servicesVariant={servicesVariant}
						/>
					);
				})}
			</div>
		);
	}
}

export default Cards;
