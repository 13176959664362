import React, { Component } from 'react';
import { withFirebase } from '../../components/Firebase';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';
import m3logo from './assets/m3logo.svg';
import * as ROUTES from '../../constants/routes';

import Icon from '../../ui/Icon';

class AdminHeader extends Component {
    render() {
        const { firebase } = this.props;

        return (
            <div className={styles.header}>
                <img src={m3logo} alt="logo" className={styles.logo}/>
                <div className={styles.icons}>
                    <Link to={ROUTES.LANDING}><Icon name="house" className={styles.icon} /></Link>
                    <Link to={ROUTES.ACCOUNT}><Icon name="gear" className={styles.icon} /></Link>
                    <Icon onClick={firebase.doSignOut} name="logout" className={styles.icon} />
                </div>
            </div>
        );
    }; 
};

export default withFirebase(AdminHeader);