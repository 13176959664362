import React, { Component } from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

import Icon from "../Icon";

class Card extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
        };
    }

    setWidth = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const {
            item: {
                icon,
                title,
                subtitles,
                imageURL,
                name,
                logoURL: logo,
                flag,
                place,
                dispozice,
                plocha,
                cena,
                url,
                company,
                address,
                ico,
                dic,
                info,
                email,
                phone,
                iconMargin,
                userEmail,
                companyLogo,
            },
            servicesVariant,
            className,
        } = this.props;

        return (
            <div
                className={classNames(
                    styles.card,
                    servicesVariant && styles.extraBorders,
                    className
                )}
            >
                {servicesVariant && icon && (
                    <div className={styles.iconBox}>
                        <Icon
                            name={icon}
                            className={classNames(
                                styles.icon,
                                iconMargin && styles.tie
                            )}
                        />
                    </div>
                )}
                {companyLogo && (
                    <div
                        style={{ backgroundImage: `url(${companyLogo})` }}
                        className={styles.companyLogo}
                    />
                )}

                {title && <div className={styles.title}>{title}</div>}
                {subtitles && (
                    <div className={styles.subtitles}>
                        {subtitles.map((item, i) => (
                            <div key={i} className={styles.subtitle}>
                                {item}
                                {item ===
                                    "Prodej děláme i formou aukce přes portál " && (
                                    <a
                                        href="https://www.aukcnak.cz/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.link_inline}
                                    >
                                        Aukčňák.cz
                                    </a>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {company && <div className={styles.title}>{company}</div>}

                {address && <div className={styles.address}>{address}</div>}
                {ico && <div className={styles.address}>{ico}</div>}
                {dic && <div className={styles.address}>{dic}</div>}
                {info && <div className={styles.address}>{info}</div>}
                {phone && <div className={styles.address}>{phone}</div>}
                {email && <div className={styles.email}>{email}</div>}
                {userEmail && (
                    <div className={styles.userEmail}>{userEmail}</div>
                )}

                {!servicesVariant && (
                    <div
                        className={styles.image}
                        style={{ backgroundImage: `url(${imageURL})` }}
                    >
                        {flag && (
                            <div
                                className={classNames(
                                    styles.flag,
                                    styles[flag]
                                )}
                            >
                                {flag === "prodej"
                                    ? "V prodeji"
                                    : flag === "pripravujeme"
                                    ? "Připravujeme"
                                    : "Realizované"}
                            </div>
                        )}
                        {name && <div className={styles.name}>{name}</div>}
                        {place && <div className={styles.place}>{place}</div>}
                    </div>
                )}

                {!servicesVariant && (
                    <div className={styles.servicesContainer}>
                        <div
                            className={styles.logo}
                            style={{ backgroundImage: `url(${logo})` }}
                        />
                        {dispozice && (
                            <React.Fragment>
                                <div className={styles.cardInfoTitle}>
                                    Dispozice:
                                </div>
                                <div className={styles.cardInfo}>
                                    {dispozice}
                                </div>
                            </React.Fragment>
                        )}
                        {plocha && (
                            <React.Fragment>
                                <div className={styles.cardInfoTitle}>
                                    Obytná plocha:
                                </div>
                                <div className={styles.cardInfo}>{plocha}</div>
                            </React.Fragment>
                        )}
                        {cena && (
                            <React.Fragment>
                                <div className={styles.cardInfoTitle}>
                                    Cena vč. DPH:
                                </div>
                                <div className={styles.cardInfo}>{cena}</div>
                            </React.Fragment>
                        )}
                        {url && (
                            <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                Informace o projektu
                            </a>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default Card;
