import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const config = {
    apiKey: "AIzaSyC9pBSiN2AHbmctJEovvqLAdxZ-LAsr4MI",
    authDomain: "fir-app-69125.firebaseapp.com",
    databaseURL: "https://fir-app-69125.firebaseio.com",
    projectId: "fir-app-69125",
    storageBucket: "gs://fir-app-69125.appspot.com", // "fir-app-69125.appspot.com",
    messagingSenderId: "1062062465665",
    appId: "1:1062062465665:web:1a6d227b4268385b",
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.database();
        this.storage = app.storage();
        this.storageRef = this.storage.ref();
    }

    // *** Auth API ***
    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = (password) =>
        this.auth.currentUser.updatePassword(password);

    // *** User API ***
    user = (uid) => this.db.ref(`users/${uid}`);

    database = () => this.db.ref("database");

    // *** DB upload ***
    writePeople = (company, phone, userEmail, id, info) => {
        this.db.ref("database/people/" + id).set({
            icon: "user",
            company,
            phone,
            userEmail,
            info,
        });
    };

    writeCompany = (address, dic, email, ico, info, id) => {
        this.db.ref("database/companies/" + id).set({
            address,
            dic,
            email,
            ico,
            info,
        });
    };
    writeNewCard = (
        cena,
        dispozice,
        flag,
        name,
        place,
        plocha,
        url,
        date,
        uuid,
        image,
        logo,
        oldImageURL,
        oldLogoURL
    ) => {
        if (image && !logo) {
            this.storage
                .ref(`images/${uuid}/`)
                .put(image)
                .then(() => {
                    const imageRef = this.storageRef.child(`images/${uuid}/`);
                    imageRef.getDownloadURL().then((imageURL) => {
                        const cardData = {
                            imageURL,
                            logoURL: oldLogoURL,
                            cena,
                            dispozice,
                            flag,
                            name,
                            place,
                            plocha,
                            url,
                            date,
                            uuid,
                        };
                        const updates = {};
                        updates["database/cards/" + uuid] = cardData;
                        return this.db.ref().update(updates);
                    });
                });
        } else if (logo && !image) {
            this.storage
                .ref(`logos/${uuid}/`)
                .put(logo)
                .then(() => {
                    const logoRef = this.storageRef.child(`logos/${uuid}/`);
                    logoRef.getDownloadURL().then((logoURL) => {
                        const cardData = {
                            imageURL: oldImageURL,
                            logoURL,
                            cena,
                            dispozice,
                            flag,
                            name,
                            place,
                            plocha,
                            url,
                            date,
                            uuid,
                        };
                        const updates = {};
                        updates["database/cards/" + uuid] = cardData;
                        return this.db.ref().update(updates);
                    });
                });
        } else if (image && logo) {
            this.storage
                .ref(`images/${uuid}/`)
                .put(image)
                .then(() => {
                    const imageRef = this.storageRef.child(`images/${uuid}/`);
                    imageRef.getDownloadURL().then((imageURL) => {
                        this.storage
                            .ref(`logos/${uuid}/`)
                            .put(logo)
                            .then(() => {
                                const logoRef = this.storageRef.child(
                                    `logos/${uuid}/`
                                );
                                logoRef.getDownloadURL().then((logoURL) => {
                                    const cardData = {
                                        imageURL,
                                        logoURL,
                                        cena,
                                        dispozice,
                                        flag,
                                        name,
                                        place,
                                        plocha,
                                        url,
                                        date,
                                        uuid,
                                    };
                                    const updates = {};
                                    updates[
                                        "database/cards/" + uuid
                                    ] = cardData;
                                    return this.db.ref().update(updates);
                                });
                            });
                    });
                });
        } else {
            const cardData = {
                imageURL: oldImageURL,
                logoURL: oldLogoURL,
                cena,
                dispozice,
                flag,
                name,
                place,
                plocha,
                url,
                date,
                uuid,
            };
            const updates = {};
            updates["database/cards/" + uuid] = cardData;
            return this.db.ref().update(updates);
        }
    };

    deleteCard = (uuid) => {
        this.storageRef.child(`images/${uuid}/`).delete();
        this.storageRef.child(`logos/${uuid}/`).delete();
        return this.db.ref("database/cards/" + uuid).remove();
    };
}

export default Firebase;
