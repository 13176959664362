import React, { Component } from 'react';

import styles from '../EditPeopleForm/form_styles.module.scss';
import { withFirebase } from '../../components/Firebase';

class EditCompanyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: '',
            dic: '',
            email: '',
            ico: '',
            info: '',
            init: true,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.init && !props.loading) {
            return {
                init: false,
                ...props.user,
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    onSubmit = event => {
        const { address, dic, email, ico, info  } = this.state;
        const { id } = this.props;

        this.props.firebase.writeCompany(address, dic, email, ico, info, id);

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { address, dic, email, ico, info } = this.state;

        return (
            <div className={styles.container}>
                <form className={styles.form} onSubmit={this.onSubmit}>
                    <input
                        className={styles.input}
                        name="address"
                        value={address}
                        onChange={this.onChange}
                        placeholder="Adresa"
                    />
                    <input
                        className={styles.input}
                        name="ico"
                        value={ico}
                        onChange={this.onChange}
                        placeholder="IČO"
                    />
                    <input
                        className={styles.input}
                        name="dic"
                        value={dic}
                        onChange={this.onChange}
                        placeholder="DIČ"
                    />
                    <input
                        className={styles.input}
                        name="info"
                        value={info}
                        onChange={this.onChange}
                        placeholder="Info"
                    />
                    <input
                        className={styles.input}
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        placeholder="Email"
                    />
                    <button className={styles.input} type="submit">
                        Aktualizovat kontakt firmy
                    </button>
                </form>
            </div>
        );
    }; 
};

export default withFirebase(EditCompanyForm);