import React, { Component } from "react";
import { compose } from "recompose";

import { withFirebase } from "../../components/Firebase";
import { withAuthorization } from "../../components/Session";

import styles from "./style.module.scss";

import AdminHeader from "../../ui-admin/AdminHeader";
import EditDropdown from "../../ui-admin/EditDropdown";
import EditPeopleForm from "../../ui-admin/EditPeopleForm";
import EditCompanyForm from "../../ui-admin/EditCompanyForm";
import AddNewProjectForm from "../../ui-admin/AddNewProjectForm";

class AdminPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cards: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.props.firebase.database().on("value", (snapshot) => {
            const database = snapshot.val();

            const cards = database.cards && Object.values(database.cards);

            this.setState({
                database,
                cards: database.cards === undefined ? [] : cards,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.database().off();
    }

    render() {
        const { cards: notSorted, database, loading } = this.state;

        let prodej = [];
        let pripravujeme = [];
        let realizovano = [];

        notSorted.forEach((card) => {
            switch (card.flag) {
                case "prodej":
                    prodej.push(card);
                    break;
                case "pripravujeme":
                    pripravujeme.push(card);
                    break;
                default:
                    realizovano.push(card);
                    break;
            }
        });

        prodej.sort((a, b) => {
            return b.date - a.date;
        });
        pripravujeme.sort((a, b) => {
            return b.date - a.date;
        });
        realizovano.sort((a, b) => {
            return b.date - a.date;
        });

        const cards = [...prodej, ...pripravujeme, ...realizovano];

        return (
            <React.Fragment>
                <AdminHeader />
                <div className={styles.container}>
                    <h2>Nový projekt</h2>
                    <AddNewProjectForm />

                    <h2>Editace aktuálních projektů</h2>
                    {cards.map((card, i) => (
                        <EditDropdown
                            key={i}
                            card={card}
                            firebase={this.props.firebase}
                        />
                    ))}

                    <h2>Editace kontaktů firem</h2>
                    <h3>VL&Partneři s.r.o.</h3>
                    <EditCompanyForm
                        loading={loading}
                        user={database && database.companies.partneri}
                        id="partneri"
                    />
                    <h3>VL Reality s.r.o.</h3>
                    <EditCompanyForm
                        loading={loading}
                        user={database && database.companies.reality}
                        id="reality"
                    />
                    <h3>VL Consulting s.r.o.</h3>
                    <EditCompanyForm
                        loading={loading}
                        user={database && database.companies.consulting}
                        id="consulting"
                    />

                    <h2>Editace kontaktů osob</h2>
                    <EditPeopleForm
                        loading={loading}
                        user={database && database.people.one}
                        id="one"
                    />
                    <br />
                    <EditPeopleForm
                        loading={loading}
                        user={database && database.people.two}
                        id="two"
                    />
                    <br />
                    <EditPeopleForm
                        loading={loading}
                        user={database && database.people.three}
                        id="three"
                    />
                    <br />
                    <EditPeopleForm
                        loading={loading}
                        user={database && database.people.four}
                        id="four"
                    />
                </div>
            </React.Fragment>
        );
    }
}

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(AdminPage);
