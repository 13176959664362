import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';

import Uvod from "./pages/Uvod";
import SignIn from "./pages/SignIn";
import Admin from "./pages/Admin";
import Account from "./pages/Account";
import PasswordForget from "./pages/PasswordForget";

import Container from "./ui/Container";
import Header from "./ui/Header";

import NoTouch from "./components/NoTouch";
import { withAuthentication } from './components/Session';
import * as ROUTES from './constants/routes';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="app">
          <NoTouch />
          <Header />
          <Container>
            <AnimatedSwitch
              atEnter={{ opacity: 0}}
              atLeave={{ opacity: 0}}
              atActive={{ opacity: 1}}>
              <Route path={ROUTES.LANDING} component={Uvod} exact={true} />
              <Route path={ROUTES.SIGN_IN} component={SignIn} />
              <Route path={ROUTES.ADMIN} component={Admin} />
              <Route path={ROUTES.ACCOUNT} component={Account} />
              <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForget} />
              <Route component={Uvod} />
            </AnimatedSwitch>
          </Container>
        </div>
      </BrowserRouter>
    );
  }
}

export default withAuthentication(App);
