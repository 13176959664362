import React, { Component } from 'react';
import moment from 'moment';
import uuidv4 from 'uuid/v4';

import styles from '../EditPeopleForm/form_styles.module.scss';
import { withFirebase } from '../../components/Firebase';

import FlagButtons from '../FlagButtons';

class AddNewProjectForm extends Component {
    constructor(props) {
        super(props);

        this.initState = {
            cena: '',
            dispozice: '',
            flag: 'prodej',
            name: '',
            place: '',
            plocha: '',
            url: '',
        };

        this.state = {
            ...this.initState,
            init: true,
        };


        this.imageInputRef = React.createRef();
        this.logoInputRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.init && !props.loading) {
            return {
                init: false,
                ...props.card,
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    onSubmit = event => {
        event.preventDefault();
        const { cena, dispozice, flag, name, place, plocha, url } = this.state;

        const uuid = uuidv4();
        const date = moment().unix();

        const uploadedImage = this.imageInputRef.current.files[0];
        const uploadedLogo = this.logoInputRef.current.files[0];

        const image = uploadedImage ? uploadedImage : undefined;
        const logo = uploadedLogo ? uploadedLogo : undefined;

        if (!image || !logo) {
            alert('Obrázek nebyl vložen!');
            return;
        }

        this.props.firebase.writeNewCard(cena, dispozice, flag, name, place, plocha, url, date, uuid, image, logo);
        
        document.getElementById("form").reset();
        this.setState({ ...this.initState});
    };

    onEditSubmit = event => {
        event.preventDefault();
        const { cena, dispozice, flag, name, place, plocha, url } = this.state;
        const { card } = this.props;

        const uuid = card.uuid;
        const date = card.date; // 1564597114
        const oldImageURL = card.imageURL;
        const oldLogoURL = card.logoURL;

        const uploadedImage = this.imageInputRef.current.files[0];
        const uploadedLogo = this.logoInputRef.current.files[0];

        const image = uploadedImage ? uploadedImage : undefined;
        const logo = uploadedLogo ? uploadedLogo : undefined;

        this.props.firebase.writeNewCard(cena, dispozice, flag, name, place, plocha, url, date, uuid, image, logo, oldImageURL, oldLogoURL);
        this.setState({ init: false, ...this.props.card});
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeFlag = (flag) => {
        this.setState({ flag });
    };


    render() {
        const { cena, dispozice, flag, name, place, plocha, url } = this.state;
        const { card } = this.props;

        return (
            <div className={styles.container}>
                <form id="form" className={styles.form} onSubmit={card ? this.onEditSubmit : this.onSubmit}>
                    Obrázek {card && '- Pokud nebude zadán, ponechá se původní.'}
                    <input className={styles.input} type="file" accept="image/*" ref={this.imageInputRef} id="imageInput" />
                    Logo projektu {card && '- Pokud nebude zadán, ponechá se původní.'}
                    <input className={styles.input} type="file" accept="image/*" ref={this.logoInputRef} id="logoInput" />
                    <input
                        className={styles.input}
                        name="name"
                        value={name}
                        onChange={this.onChange}
                        placeholder="Název"
                    />
                    <input
                        className={styles.input}
                        name="place"
                        value={place}
                        onChange={this.onChange}
                        placeholder="Adresa"
                    />
                    <input
                        className={styles.input}
                        name="dispozice"
                        value={dispozice}
                        onChange={this.onChange}
                        placeholder="Dispozice"
                    />
                    <input
                        className={styles.input}
                        name="plocha"
                        value={plocha}
                        onChange={this.onChange}
                        placeholder="Obytná plocha"
                    />
                    <input
                        className={styles.input}
                        name="cena"
                        value={cena}
                        onChange={this.onChange}
                        placeholder="Cena"
                    />
                    <input
                        className={styles.input}
                        name="url"
                        value={url}
                        onChange={this.onChange}
                        placeholder="Odkaz / URL"
                    />
                    <FlagButtons 
                        value={flag}
                        onChange={this.onChangeFlag}
                    />
                    <button className={styles.input} type="submit">
                        {card ? 'Uložit změny' : 'Přidat nový projekt'}
                    </button>
                </form>
            </div>
        );
    }; 
};

export default withFirebase(AddNewProjectForm);