import React, { Component } from "react";

import styles from "./style.module.scss";

import { TweenLite, TimelineLite } from "gsap";

class DropdownSection extends Component {
    state = {};

    contentRef = React.createRef();
    leftLineRef = React.createRef();
    rightLineRef = React.createRef();

    toggle = () => {
        if (this.state.info) {
            this.close();
            this.setState({ info: false });
        } else {
            this.open();
            this.setState({ info: true });
        }
    };

    open = () => {
        const tl = new TimelineLite();
        TweenLite.to(this.leftLineRef.current, 0.3, {
            delay: 0.5,
            rotation: -45,
        });
        TweenLite.to(this.rightLineRef.current, 0.3, {
            delay: 0.5,
            rotation: 45,
        });

        tl.set(this.contentRef.current, {
            height: "auto",
            autoAlpha: 1,
            onComplete: () => {
                this.height = this.contentRef.current.getBoundingClientRect().height;
            },
        }).set(this.contentRef.current, {
            autoAlpha: 0,
            height: 0,
            onComplete: () =>
                TweenLite.to(this.contentRef.current, 0.3, {
                    autoAlpha: 1,
                    height: this.height,
                    onComplete: () => this.scrollTo("#content"),
                }),
        });
    };

    close = () => {
        TweenLite.to(this.leftLineRef.current, 0.3, {
            delay: 0.5,
            rotation: 45,
        });
        TweenLite.to(this.rightLineRef.current, 0.3, {
            delay: 0.5,
            rotation: -45,
        });
        TweenLite.to(this.contentRef.current, 0.3, {
            autoAlpha: 0,
            height: 0,
        });
    };

    scrollTo = (id) => {
        TweenLite.to(window, 0.3, {
            delay: 0.2,
            scrollTo: { y: id, offsetY: -90, autoKill: false },
        });
    };

    render() {
        const { subtitle, children } = this.props;
        return (
            <div className={styles.footer}>
                <div className={styles.header} onClick={this.toggle}>
                    <div className={styles.subtitle}>{subtitle}</div>
                    <div className={styles.animation}>
                        <div
                            ref={this.leftLineRef}
                            className={styles.rightLine}
                        />
                        <div
                            ref={this.rightLineRef}
                            className={styles.leftLine}
                        />
                    </div>
                </div>

                <div
                    ref={this.contentRef}
                    className={styles.content}
                    id="content"
                >
                    {children}
                </div>
            </div>
        );
    }
}

export default DropdownSection;
