import React from 'react';

import styles from './style.module.scss';
import logo from '../Header/assets/logo_white.svg';

const IntroImage = ({ id }) => <div id={id} className={styles.introImage}>
    <div className={styles.parallax}>
        <div className={styles.overlay} style={{ backgroundImage: `url(${logo})` }} />
    </div>
</div>

export default IntroImage;
