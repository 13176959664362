import React, { Component } from "react";
// import classNames from "classnames";
import styles from "./style.module.scss";

import Contact from "../Contact";

class Contacts extends Component {
    render() {
        const { list } = this.props;

        return (
            <div className={styles.contacts}>
                {list.map((item, i) => {
                    return (
                        <div key={i} className={styles.contacts_item}>
                            <Contact data={item} />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Contacts;
