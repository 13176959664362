import React from 'react';
import isTouch from '../../utils/istouch';

class NoTouch extends React.PureComponent {
	componentDidMount = () => {
		if (!isTouch()) {
			document.body.classList.add('no-touch');
		}
	};

	render() {
		return null;
	}
}

export default NoTouch;