import React, { Component } from "react";
import Transition from "react-transition-group/Transition";
import { TweenLite } from "gsap";
import { withRouter } from "react-router-dom";

import styles from "./style.module.scss";
import logo from "./assets/logo_white.svg";

import Link from "../Link";
import GoUp from "../GoUp";

class Header extends Component {
    constructor(props) {
        super(props);

        this.headerRef = React.createRef();
        this.menuRef = React.createRef();
        this.topLineRef = React.createRef();
        this.middleLineRef = React.createRef();
        this.bottomLineRef = React.createRef();

        this.state = {
            isOpen: false,
            width: 0,
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.toggleHeader);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.toggleHeader);
    }

    scrollTo = (id, offsetY = 117) => () => {
        TweenLite.to(window, 1, {
            scrollTo: { y: id, offsetY, autoKill: false },
        });
        this.toggleMenu();
    };

    toggleHeader = () => {
        this.setState({ isOpen: window.scrollY > 50 });
    };

    openHeader = () => {
        TweenLite.to(this.headerRef.current, 0.3, {
            background: "rgba(4,30,66,1)",
            paddingTop: "17px",
        });
    };

    closeHeader = () => {
        TweenLite.to(this.headerRef.current, 0.2, {
            background: "rgba(0,0,0,0)",
            paddingTop: "25px",
        });
    };

    toggleMenu = () => {
        if (this.state.isOpenMenu) {
            this.closeMenu();
            this.setState({ isOpenMenu: false });
        } else {
            this.openMenu();
            this.setState({ isOpenMenu: true });
        }
    };
    openMenu = () => {
        TweenLite.to(this.middleLineRef.current, 0.2, {
            autoAlpha: 0,
        });
        TweenLite.to(this.topLineRef.current, 0.3, {
            y: 9,
            rotation: 23,
        });
        TweenLite.to(this.bottomLineRef.current, 0.3, {
            y: -9,
            rotation: -23,
        });
        TweenLite.to(this.menuRef.current, 0.3, {
            autoAlpha: 1,
            height: 309,
        });
    };
    closeMenu = () => {
        TweenLite.to(this.middleLineRef.current, 0.2, {
            delay: 0.1,
            autoAlpha: 1,
        });
        TweenLite.to(this.topLineRef.current, 0.3, {
            y: 0,
            rotation: 0,
        });
        TweenLite.to(this.bottomLineRef.current, 0.3, {
            y: 0,
            rotation: 0,
        });
        TweenLite.to(this.menuRef.current, 0.3, {
            autoAlpha: 0,
            height: 0,
        });
    };

    render() {
        return (
            this.props.location.pathname === "/" && (
                <Transition
                    in={this.state.isOpen}
                    onEnter={this.openHeader}
                    onExit={this.closeHeader}
                    timeout={300}
                >
                    <div
                        className={styles.header}
                        id="headerID"
                        ref={this.headerRef}
                    >
                        <GoUp hidden={!this.state.isOpen} />
                        <div className={styles.mobileControls}>
                            <div
                                className={styles.logo}
                                style={{ backgroundImage: `url(${logo})` }}
                            />
                            <div className={styles.menuMobile}>
                                <Link
                                    name="Domů"
                                    onClick={this.scrollTo("#domu", 0)}
                                />
                                <Link
                                    name="Naše služby"
                                    onClick={this.scrollTo("#nase_sluzby")}
                                />
                                <Link
                                    name="Developerské projekty"
                                    onClick={this.scrollTo(
                                        "#developerske_projekty"
                                    )}
                                />
                                <Link
                                    name="Reality"
                                    onClick={this.scrollTo("#reality", 137)}
                                />
                                <Link
                                    name="Kontakty"
                                    onClick={this.scrollTo("#kontakt", 137)}
                                />
                                <Link
                                    name="Informace pro spotřebitele"
                                    onClick={this.scrollTo("#informace", 0)}
                                />
                            </div>
                            <div
                                className={styles.animation}
                                onClick={this.toggleMenu}
                            >
                                <div
                                    className={styles.line}
                                    ref={this.topLineRef}
                                />
                                <div
                                    className={styles.line}
                                    ref={this.middleLineRef}
                                />
                                <div
                                    className={styles.line}
                                    ref={this.bottomLineRef}
                                />
                            </div>
                        </div>

                        <div
                            className={styles.containerMenu}
                            ref={this.menuRef}
                        >
                            <div className={styles.menu}>
                                <Link
                                    name="Domů"
                                    onClick={this.scrollTo("#domu", 0)}
                                />
                                <Link
                                    name="Naše služby"
                                    onClick={this.scrollTo("#nase_sluzby")}
                                />
                                <Link
                                    name="Developerské projekty"
                                    onClick={this.scrollTo(
                                        "#developerske_projekty"
                                    )}
                                />
                                <Link
                                    name="Reality"
                                    onClick={this.scrollTo("#reality", 97)}
                                />
                                <Link
                                    name="Kontakt"
                                    onClick={this.scrollTo("#kontakt")}
                                />
                                <Link
                                    name="Informace pro spotřebitele"
                                    onClick={this.scrollTo("#informace", 0)}
                                />
                            </div>
                        </div>
                    </div>
                </Transition>
            )
        );
    }
}

export default withRouter(Header);
