import React, { Component } from 'react';
import classNames from 'classnames';
import { TweenLite } from "gsap";

import styles from './style.module.scss';
import Icon from "../../ui/Icon";
import AddNewProjectForm from "../AddNewProjectForm";


class AdminHeader extends Component {
    state = {};

    editRef = React.createRef();

    toggleDropdown = () => {
        if (this.state.isOpen) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    };

    openDropdown = () => {
        this.editRef.current.style.height = 'auto';
        const height = this.editRef.current.getBoundingClientRect().height;
        this.editRef.current.style.height = '0';

        TweenLite.to(this.editRef.current, 0.2, {
            autoAlpha: 1,
            height,
        });
        this.setState({ isOpen: true });
    };

    closeDropdown = () => {
        TweenLite.to(this.editRef.current, 0.2, {
            autoAlpha: 0,
            height: 0,
        });
        this.setState({ isOpen: false });
    };

    deleteCard = (uuid) => {
        this.props.firebase.deleteCard(uuid);
    };

    render() {
        const { card } = this.props;
        return (
            <div className={classNames(styles.editDropdown, card.flag === 'prodej' ? styles.prodej : card.flag === 'realizovano' ? styles.realizovano : styles.pripravujeme)}>
                <div className={styles.editCardTitle}>{card.name}</div>
                <div className={styles.editCardsubtitle}>{card.place}</div>
                <div>{card.flag}</div>
                <div className={styles.icons}>
                    <Icon name="waste-bin" className={styles.editCardIcon} onClick={() => this.deleteCard(card.uuid)} />
                    <Icon name="edit" className={styles.editCardIcon} onClick={this.toggleDropdown} />
                </div>
                <div className={styles.dropdown} ref={this.editRef}>
                    <AddNewProjectForm card={card} />
                </div>
            </div>
        );
    }; 
};

export default AdminHeader;