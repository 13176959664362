import React from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

const Title = ({ id, text, white, noMargin }) => (
	<div id={id} className={classNames(styles.title, white && styles.whiteTitle, noMargin && styles.noMarginTitle)}>
		{text}
		<div className={classNames(styles.underline, white && styles.whiteUnderline)} />
	</div>
);

export default Title;
