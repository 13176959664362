import React, { memo } from 'react';
import classNames from 'classnames';
import './font/flaticon.css';

const Icon = ({ className, name, onClick, reference }) => (
	<i className={classNames(`flaticon-${name}`, className)} onClick={onClick} ref={reference} />
);


Icon.defaultProps = {
	className: '',
};

export default memo(Icon);
