import React, { Component } from "react";

import styles from "./form_styles.module.scss";
import { withFirebase } from "../../components/Firebase";

class EditPeopleForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company: "",
            phone: "",
            userEmail: "",
            info: "",
            init: true,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.init && !props.loading) {
            return {
                init: false,
                ...props.user,
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    onSubmit = (event) => {
        const { company, phone, userEmail, info } = this.state;
        const { id } = this.props;

        this.props.firebase.writePeople(company, phone, userEmail, id, info);

        event.preventDefault();
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { company, phone, userEmail, info } = this.state;

        return (
            <div className={styles.container}>
                <form className={styles.form} onSubmit={this.onSubmit}>
                    <input
                        className={styles.input}
                        name="company"
                        value={company}
                        onChange={this.onChange}
                        placeholder="Jméno"
                    />
                    <input
                        className={styles.input}
                        name="info"
                        value={info}
                        onChange={this.onChange}
                        placeholder="Další informace"
                    />
                    <input
                        className={styles.input}
                        name="phone"
                        value={phone}
                        onChange={this.onChange}
                        placeholder="Telefon"
                    />
                    <input
                        className={styles.input}
                        name="userEmail"
                        value={userEmail}
                        onChange={this.onChange}
                        placeholder="E-mail"
                    />
                    <button className={styles.input} type="submit">
                        Aktualizovat kontakt
                    </button>
                </form>
            </div>
        );
    }
}

export default withFirebase(EditPeopleForm);
