import React, { Component } from "react";
import { TweenLite } from "gsap";
import classNames from "classnames";

import styles from './style.module.scss';

import Icon from '../Icon';

class GoUp extends Component {
    scrollToHeader = () => {
      TweenLite.to(window, 1, {
        scrollTo: { y: '#domu', autoKill: false },
      });
    };

    render() {
      const { hidden } = this.props;
      return (
        <div className={classNames(styles.goup, hidden && styles.hidden)} onClick={this.scrollToHeader}  onMouseEnter={this.enter} onMouseLeave={this.leave} >
          <Icon name="navigate-up-arrow" className={styles.nahoru}>nahoru</Icon>
        </div>
      );
    }
  }
  

export default GoUp