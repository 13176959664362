import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

const FlagButtons = ({ onChange, value }) => (
    <div className={styles.container}>
        <div onClick={() => onChange('prodej')} className={classNames(styles.button, value === 'prodej' && styles.prodej )}>
            Prodej
        </div>
        <div onClick={() => onChange('pripravujeme')} className={classNames(styles.button, value === 'pripravujeme' && styles.pripravujeme )}>
            Připravujeme
        </div>
        <div onClick={() => onChange('realizovano')} className={classNames(styles.button, value === 'realizovano' && styles.realizovano )}>
            Realizováno
        </div>
    </div>
);

export default FlagButtons;